// @import 'normalize.css';

//responsive
$width-ipad: 1280px;
$width-mobile: 768px;

:root {
    --primary: #82aae3;
    --font-family: Arial, Helvetica, sans-serif;

    //defaut layout
    --default-layout-header-height: 60px;
    --default-layout-width: 1360px;
    --default-layout-horizontal-spacer: 24px;

    --height-chatAI: calc(100vh-var(--default-layout-header-height));

    // --width-search: 500px;
    --text-color: rgb(22, 24, 35);
    --text-color-secondnary: #ffffff;
    --text-color-link: #82aae3;
    --text-color-link-hightlight: #b799ff;
    --background-color: #ffffff;
    --background-color-secondnary: #eafdfc;
    --background-button: #bfeaf5;
    --background-button-red: rgba(239, 98, 98, 0.6);
}

* {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

body {
    font-size: 1rem;
    font-family: var(--font-family);
    line-height: 1.5;
    text-rendering: optimizespeed;
    overflow-y: overlay;
}

// Scrollbar CSS
html *::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
}

html *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.15);
}

html *::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
}

button,
input,
[tabindex] {
    outline: none;
    border: none;
}

a {
    text-decoration: none;
}

// Fonts emmbeded

// Custom tippy tooltip
body {
    .tippy-box {
        border-radius: 8px;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.375rem;
        background-color: rgba(84, 84, 84, 0.92);
    }

    .tippy-arrow {
        color: rgba(84, 84, 84, 0.92);
    }

    .tippy-content {
        padding: 8px 9px;
    }

    .tippy-box[data-placement^='bottom'] > .tippy-arrow:before {
        top: -8px;
    }
}

// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;
