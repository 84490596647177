.wrapper {
    min-width: 80px;
    min-height: 30px;
    color: var(--text-color);
}

.primary {
    background-color: var(--background-button) !important;
    border: 1px solid var(--primary) !important;

    &:hover {
        background: #0074e4 !important;
        color: var(--text-color-secondnary);
    }
}

.red {
    background-color: var(--background-button-red) !important;
    border: 1px solid rgb(239, 98, 98) !important;

    &:hover {
        background: rgb(239, 98, 98) !important;
        color: var(--text-color-secondnary);
    }
}
// }
