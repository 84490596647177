@import '/src/components/GlobalStyles/GlobalStyles';

.wrapper {
    max-height: 95%;
    animation: slip 0.2s ease-in-out;
}

@keyframes slip {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
