.wrapper {
    padding-left: 10%;
}

.title {
    color: var(--text-color);
}

.btn-filter {
    background-color: var(--background-color);

    &:hover {
        color: var(--text-color-secondnary);
        background-color: var(--primary);
    }
}

.btn-active {
    color: var(--text-color-secondnary);
    background-color: var(--primary);
}
