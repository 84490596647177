.WrapperAuth {
    .form {
        button {
            width: 100%;
            height: 38px;
        }

        button + button {
            margin-bottom: 25px;
        }
    }

    footer {
        a {
            margin-left: 10px;
        }

        a:hover {
            text-decoration: underline;
        }
    }

    .btn-changeLanguage {
        &:hover {
            color: var(--text-color-link-hightlight);
        }
    }
}

//background
.wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: url('../../../assets/img/wave.png');
    background-size: 1000px 100px;
}

.wave.wave1 {
    opacity: 1;
    animation-delay: 0;
    bottom: 0;
    z-index: 4;
    animation: animate 30s linear infinite;
}

.wave.wave2 {
    opacity: 0.5;
    animation-delay: -5s;
    bottom: 10;
    z-index: 3;
    animation: animate2 15s linear infinite;
}

.wave.wave3 {
    opacity: 0.2;
    animation-delay: -2s;
    bottom: 15;
    z-index: 2;
    animation: animate 30s linear infinite;
}

.wave.wave4 {
    opacity: 0.7;
    animation-delay: -5s;
    bottom: 20;
    z-index: 1;
    animation: animate 5s linear infinite;
}

@keyframes animate {
    0% {
        background-position-x: 0;
    }
    100% {
        background-position-x: 1000px;
    }
}

@keyframes animate2 {
    0% {
        background-position-x: 0;
    }
    100% {
        background-position-x: 1000px;
    }
}
