.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    margin-top: var(--default-layout-header-height);
    display: flex;
    justify-content: center;
}
