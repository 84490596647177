@import '/src/components/GlobalStyles/GlobalStyles.scss';

$width-box: 180px;
$margin-left-right-box: 20px;

.wrapper {
    padding-left: calc((100% - 4 * ($width-box + 2 * $margin-left-right-box)) / 2);
    padding-right: calc((100% - 4 * ($width-box + 2 * $margin-left-right-box)) / 2);
}

.item-box {
    margin-left: $margin-left-right-box;
    margin-right: $margin-left-right-box;
    width: $width-box;
    height: $width-box;
    border: rgba(0, 0, 0, 0.2) 1px solid;
    background-color: var(--background-color-secondnary);

    &:hover {
        transform: translateY(-1px);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border-top: rgba(0, 0, 0, 0.2) 1px solid;
        transition: all 0.3s ease-in-out;
    }
}

.create-folder {
    background: var(--background-color) !important;
}

.content {
    color: var(--text-color-link);
}

//responsive

@media (max-width: 980px) {
    .wrapper {
        padding-left: calc((100% - 3 * ($width-box + 2 * $margin-left-right-box)) / 2);
        padding-right: calc((100% - 3 * ($width-box + 2 * $margin-left-right-box)) / 2);
    }
}

@media (max-width: $width-mobile) {
    .wrapper {
        padding-left: calc((100% - 2 * ($width-box + 2 * $margin-left-right-box)) / 2);
        padding-right: calc((100% - 2 * ($width-box + 2 * $margin-left-right-box)) / 2);
    }
}
